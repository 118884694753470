body {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  min-height: 100vh;
  width: 100vw;
}

header {
  font-size: 5rem;
  padding: 3rem;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(1, 1);
  }
}

.sale {
  padding: 4rem;
  font-size: 4rem;
  text-transform: uppercase;
  animation: zoominoutsinglefeatured 1.5s ease-in;
  width: 100%;
  text-align: center;
}

.contact {
  background-color: #58b483;
  width: 100%;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
  box-shadow: 0 50vh 0 50vh #58b483;
  padding: 5rem;
}

.contact * {
  padding: 2rem;
}

.email {
  text-decoration: underline overline;
  color: #000;
  font-size: 3rem;
}
